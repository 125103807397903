import Head from 'next/head';
import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from './_app';
import React from 'react';
import Layout from '../layouts';
import { AppSettings } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import { getTdkApi } from '@/lib/service/tdk';

const LoginContent = dynamic(() => import('@/components/LoginPage'), {
  ssr: false,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req, resolvedUrl } = context;
  const tdkData = await getTdkApi(resolvedUrl, locale);
  const localeRes = await serverSideTranslations(locale, ['common']);
  let currentURL = `https://${req.headers.host}${resolvedUrl}`;
  if (locale != 'en') {
    currentURL = `https://${req.headers.host}/${locale}${resolvedUrl}`;
  }
  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};

  return {
    props: {
      ...localeRes,
      herf: currentURL,
      host: req.headers.host,
      locale,
      title: tdkData?.data?.title ?? tempData?.head_Meta_Title ?? null,
      description: tdkData?.data?.description ?? tempData?.head_Meta_Desc ?? null,
    },
  };
};

const Home: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (props: any) => {
  return <LoginContent data={props} />;
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title;
  const desc = page?.props?.description;

  return (
    <Layout
      head={
        <Head>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta
            property="og:image"
            content={'https://res-front.pumpsoul.com/prod-erasa/image/dmca-protect/dmca-5.png'}
          />
          <meta
            property="og:image:secure_url"
            content={'https://res-front.pumpsoul.com/prod-erasa/image/dmca-protect/dmca-5.png'}
          />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta
            name="twitter:image"
            content={'https://res-front.pumpsoul.com/prod-erasa/image/dmca-protect/dmca-5.png'}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta
            name="viewport"
            content="width=minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
          />

          <link rel="alternate" hrefLang="en" href={`${process.env.NEXT_APP_WEB_HOST}/en/login`} />
          <link rel="alternate" hrefLang="tw" href={`${process.env.NEXT_APP_WEB_HOST}/tw/login`} />
          <link rel="alternate" hrefLang="zh" href={`${process.env.NEXT_APP_WEB_HOST}/zh/login`} />
          <link rel="alternate" hrefLang="ja" href={`${process.env.NEXT_APP_WEB_HOST}/ja/login`} />
          <link rel="alternate" hrefLang="ko" href={`${process.env.NEXT_APP_WEB_HOST}/ko/login`} />
          <link rel="alternate" hrefLang="pt" href={`${process.env.NEXT_APP_WEB_HOST}/pt/login`} />
          <link rel="alternate" hrefLang="de" href={`${process.env.NEXT_APP_WEB_HOST}/de/login`} />
          <link rel="alternate" hrefLang="es" href={`${process.env.NEXT_APP_WEB_HOST}/es/login`} />
          <link rel="alternate" hrefLang="fr" href={`${process.env.NEXT_APP_WEB_HOST}/fr/login`} />
          <link rel="alternate" hrefLang="it" href={`${process.env.NEXT_APP_WEB_HOST}/it/login`} />
          <link rel="alternate" hrefLang="x-default" href={`${process.env.NEXT_APP_WEB_HOST}/login`} />

          <link rel="canonical" href={page.props?.host} />
          <link rel="icon" href={AppSettings.icoPath} />
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
